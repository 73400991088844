export default {
  "message": {
    "try": {
      "applesQty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no apple"]), _normalize([_interpolate(_named("n")), " apple"]), _normalize(["two_form"]), _normalize([_interpolate(_named("n")), " apple"]), _normalize([_interpolate(_named("n")), " apple"])])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the project"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface language"])},
      "queues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My queues"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
    },
    "main": {
      "landingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.lnd.queues.top/en"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
      "cleanQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean Queue"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
      "cleanQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All clients currently in the queue will be deleted."])},
      "changeToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change QR Codes"])},
      "changeTokenInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may need to change the queue token and its QR codes if your queue has been the subject of spam or bullying. This action is free. Updating the token will clear the queue completely and you will have to print new QR codes."])},
      "killQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Queue"])},
      "killQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All data associated with this queue will be irretrievably deleted."])},
      "getPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a premium account"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understood"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["-"]), _normalize(["1 day"]), _normalize([_interpolate(_named("n")), " days"]), _normalize([_interpolate(_named("n")), " days"])])},
      "moreDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More days"])},
      "tooFar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than a week"])},
      "addQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Queue"])},
      "eQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Queue Administration"])},
      "holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today is a holiday"])},
      "worktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working time today"])},
      "timebreaks": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Break"]), _normalize(["Breaks"])])},
      "tabInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info and Settings"])},
      "statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current state"])},
      "statements": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day off"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-working hours"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours"])}
      ],
      "queueManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage customers in queue"])},
      "inProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Process"])},
      "queueEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue is empty"])},
      "queueSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue Settings"])},
      "sheduleSettings": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working time settings"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break settings"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join time settings"])}
      ],
      "qrAndLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Codes and Links"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "qrStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to join the queue"])},
      "qrEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to start serving"])},
      "qrPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save QR codes, print them and place them at the service point"])},
      "otherSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all application data"])},
      "wipeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wipe the application data on the device will not harm your account, created queues and their current status. You can restore access on this or another device."])},
      "wipeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear data"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "creationAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "queueCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue Creation"])},
      "queueCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new queue has been created. Configure for future use."])},
      "createAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a new account, or connect this device to an existing one. In both cases, to activate the account on the device, you need to receive a corresponding link to the e-Mail. After activation, you can use the application for free or subscribe to the paid version and get additional opportunities in case of need."])},
      "accountCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been created."])},
      "accountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need to administer your queues from several devices, register on each of them using the same e-Mail."])},
      "wipeAcnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
      "wipeAcntInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account, all queues you have created and records of customers in the queues will be deleted. Free accounts are deleted without possibility of recovery. Paid accounts can be recovered before the end of the paid validity period. There is no refund of unused funds."])},
      "accountCreatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive an email to the registered e-mail and follow the link to complete the registration. If something went wrong, you can cancel the current registration and create a new one with the same e-mail or a different one."])},
      "cancelCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel registration"])},
      "privacyBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "areementBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Agreement"])},
      "privacyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.lnd.queues.top/en/privacy"])},
      "areementUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.lnd.queues.top/en/agreement"])},
      "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read the $User Agreement$, $Privacy Policy$ and accept the terms of these documents."])},
      "timezoneSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your time zone"])},
      "timezoneInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting the time zone is necessary for correct time display in user and admin applications. If you and clients are in different time zones, set the point of service zone."])},
      "buyPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy premium"])},
      "buyPremiumInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A premium account allows you to use additional functionality of the application, namely a larger number of available queues per account, sending push notifications about the status of the queue to a larger number of customers, push communication with past customers and the ability to occupy customers while waiting with something useful, such as filling electronic form, payment of services or at least perusal of the information via the link."])},
      "buyPremiumAcnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy premium"])},
      "guide": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To join the queue, the client needs to scan the corresponding (first) QR code, follow the link and agree."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following table shows the records of all customers, according to their queue. Customers that have already been served are marked with a different color."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are three ways to record the start of service for the next customer:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. The client scans the second QR code. Works only for the client at the top of the queue."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. «Start service» button in the client application. Available if allowed by settings, and only to the client at the top of the queue."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. The «Next» button in the table below, when the queue is free. At the same time, you need to make sure that the client with the corresponding number approached you."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of appropriate setting, the queue is transferred to the «Busy» state for the entire time of working with the client, otherwise it is always free."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is possible to leave the «Busy» state and fix the end of the service either with the «Next» button in the middle of the table, or with the «Free» button in the cap."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The «Pause» button allows you to temporarily block the start of service in any way."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the settings allow, you can change the position of customers in the queue, serve them out of turn or delete them."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also block new people from being added to the queue."])}
      ]
    },
    "statement": {
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current customer service."])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free. Possible service."])},
      "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary break."])},
      "serviceAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to manually set the current status of the queue to «Busy». It is activated automatically at the start of the next customer's service. Choose exactly who you want to accept."])},
      "short": {
        "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busy"])},
        "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
        "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break"])}
      },
      "closed": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment in the queue is allowed"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment in the queue is suspended"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment in the queue is terminated, according to the schedule"])}
      ]
    },
    "dialogs": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "confirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Action"])},
      "point_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start customer service"])},
      "point_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End client service and call the next one"])},
      "point_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the customer down one position in the queue"])},
      "point_urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept this customer outside the queue"])},
      "point_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete client from queue"])},
      "point_kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove client from list"])},
      "point_recall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a push message to the client"])},
      "point_dragndrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the customer's place in the queue"])},
      "point_tail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the customer to the end of the queue"])},
      "qrComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Service"])},
      "qrQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Queue"])},
      "saveSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Setup"])},
      "userMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text"])},
      "bw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strict"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bizarre"])}
    },
    "setup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue name (not translated)"])},
      "allow_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional administrative account"])},
      "allow_admins_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows other people to securely manage the queue without being able to change settings"])},
      "allow_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients are allowed to skip queue"])},
      "allow_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use, for example, when it is important to save a place in the queue for customers who are late"])},
      "allow_urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out-of-queue service possible"])},
      "allow_urgent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use when customers are not always served in a queue, for example when an order is expected in a self-service restaurant"])},
      "auto_busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically transfer the queue to the «Busy» state"])},
      "auto_busy_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The state of the queue becomes «Busy» every time a client initiates service, regardless of who initiated it. Use this feature if servicing a client takes time. Otherwise, the queue will always be in «Idle» state"])},
      "urgent_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent button function"])},
      "urgent_button_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Move Up Queue option only changes the customer's position in the queue. Start service, if provided, must be done manually."])},
      "urgent_button_options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start service out-of-queue"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to top only"])}
      ],
      "allow_recall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify customers who have already been served"])},
      "allow_recall_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows you to notify the client, for example, that he left something behind"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify about queue status"])},
      "push_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you press the Free button, the customer at the top of the queue receives an invitation. Other customers in the queue will be notified of its status. Useful if service is strictly on a first-come, first-served basis."])},
      "push_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for service"])},
      "push_complete_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A client receives an invitation when you push the service button at its position in the queue"])},
      "push_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Phrase"])},
      "push_text_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your chosen phrase is sent to the customer in their chosen language"])},
      "push_text_options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come, please"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order is ready"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's time to start"])}
      ],
      "push_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the number of customers in the queue"])},
      "push_qty_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All other customers in the queue do not receive the message"])},
      "start_idx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start service number"])},
      "restart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update numbering when queue is empty"])},
      "restart_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh numbering at the beginning of the day"])},
      "keep_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep the queue for the next day"])},
      "save_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not delete customers served yesterday"])},
      "save_daily_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before the start of a new day, all clients that were serviced the previous day are deleted. You can block this operation if you want to keep them."])},
      "refresh_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily queue refresh time"])},
      "refresh_time_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, the queue is refreshed in the period within 15 minutes after 04:00 local time. If you are working during this time, choose an arbitrary value for the start of the period"])},
      "refresh_time_options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20:00"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22:00"])}
      ],
      "userdataAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prompt customers to fill out a form"])},
      "userdataAllowed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enables the client to use the waiting time to report important information and thereby speed up service"])},
      "userdata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Google Forms or other service"])},
      "userdata_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter name to send customer ID"])},
      "userdata_key_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is necessary to identify the customer in the form filled out by them"])},
      "userdata_keytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use token as client ID"])},
      "userdata_keytype_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, the customer's queue number is used as the customer ID. Using a unique 6-character token instead is useful if you need a completely unique identification and the numbering of customers in the queue is updated periodically"])},
      "userdata_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many customers to ignore"])},
      "userdata_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number of customers at the top of the queue will not be prompted to fill out the form because there is no time to do so"])},
      "userpayAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer online payment to customers"])},
      "userpayAllowed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enables the client to use the waiting time to pay for services and thereby speed up service"])},
      "userpay_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment page link"])},
      "userpay_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter name to send customer ID"])},
      "userpay_key_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is necessary to identify the customer in the payment"])},
      "userpay_keytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use token as customer ID"])},
      "userpay_keytype_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, the customer's queue number is used as the customer ID. Using a unique 6-character token instead is useful if you need a completely unique identification and the numbering of customers in the queue is updated periodically"])},
      "userpay_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many customers to ignore"])},
      "userpay_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number of customers at the top of the queue will not be offered online payment as there is no time for it"])},
      "userinfoAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer information links to customers"])},
      "userinfoAllowed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enables the client to use the waiting time to familiarize himself with important information and thereby reduce the number of questions during service"])},
      "userinfo_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to information resource"])},
      "userinfo_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional parameter name – client ID"])},
      "userinfo_key_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is useful, for example, to identify a customer in a link to a page"])},
      "userinfo_keytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use token as client ID"])},
      "userinfo_keytype_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, the customer's queue number is used as the customer ID. Using a unique 6-character token instead is useful if you need a completely unique identification and the numbering of customers in the queue is updated periodically"])},
      "userinfo_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many customers to ignore"])},
      "userinfo_skip_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number of customers at the top of the queue will not be offered information because there is no time for it"])},
      "show_idx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Number"])},
      "show_idx_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number is assigned to the client when queuing and is retained even if clients are dequeuing. Each client is also identified by a unique 6-character token. The token is not communicated to the client"])},
      "show_shedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work schedule"])},
      "show_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue status before the client"])},
      "show_before_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not want to show this if scheduled service is out of line"])},
      "show_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue status behind customer"])},
      "show_after_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not want to show this if scheduled service is out of order"])},
      "show_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average service time"])},
      "show_average_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated from service data of previous customers. Use if it is realistic to give the customer an opportunity to estimate the wait time"])},
      "show_selfcomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Service Button"])},
      "show_selfcomplete_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This button allows the customer to self-initiate the start of service when it's their turn. Otherwise, this is only possible by scanning the QR code or through the admin panel."])},
      "premiumInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked settings are available in the paid version"])},
      "push_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending push notifications"])},
      "order_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Rules"])},
      "idx_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue numbering"])},
      "userdata_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer data form"])},
      "info_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information for customers"])},
      "show_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User application queue information"])},
      "userpay_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online payment"])},
      "resolve_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something is wrong with the settings. Please fix the errors"])},
      "cleanup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution! Changing the settings when there are customers in the queue may lead to unexpected results. We recommend that you perform the settings when the queue is empty."])}
    },
    "validation": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is necessary to specify"])},
      "numberValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires a number in the range"])},
      "lengthValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required phrase with number of characters"])},
      "timeInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end of the period must be later than the start"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid e-mail is required"])}
    },
    "shedule": {
      "inQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Queue"])},
      "inQueueFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Queue From"])},
      "serviceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Served from"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
      "queueFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
      "inQueueTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
      "serviceTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data saved"])},
      "week": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mon"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tue"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wed"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thu"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fri"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sat"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sun"])}
      ],
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add schedule item"])},
      "titleAdd": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add working day"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add break"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add recording period"])}
      ],
      "killWorkday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this entry from the schedule"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "typeSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule Item Type"])},
      "mustWeekDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one of the days of the week must be selected."])},
      "zeroWeekDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no available days of the week to create this schedule item."])},
      "dailyBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers are allowed to queue during business hours."])},
      "addAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When adding or removing a work time record, all break records for those days of the week will be removed to prevent incompatibilities"])},
      "type": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queuing Period"])}
      ],
      "typeInfo": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must specify the start and end of the working day for the selected days of the week. There can only be one working day for each day of the week."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must specify the start and end of the break for the selected days of the week. There may be several breaks during working hours."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must specify the beginning and end of the period when customers are allowed to queue on the selected days of the week. There can be several such periods during each day of the week."])}
      ]
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot"])},
      "apiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication error. Please try again. If you are sure that there are no problems with the connection, you can delete all application data and then restore your account. Settings and queue status will not be lost."])},
      "noQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No such queue exists."])},
      "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator authorization error. Re-registration required."])},
      "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to create this account."])},
      "queueCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following queue cannot be created."])},
      "pointComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This client has already been served."])},
      "pointNotComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This client has not yet been serviced."])},
      "pointSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This client cannot give up a turn."])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to accept this client at this time."])},
      "sheduleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid schedule element type."])},
      "sheduleData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid schedule data structure."])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid request or data structure."])},
      "dnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to change the customer's position in the queue."])},
      "sheduleWeekdayDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A working day for one or more days of the week you specified already exists."])},
      "sheduleTimeCrossingBreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing breaks are crossing with the new one."])},
      "sheduleTimeCrossingBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing queue opening periods are crossed with the new one."])},
      "sheduleTimeNoInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The break created is outside of working hours."])}
    },
    "patterns": {
      "typeSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your queue template"])},
      "typeSelectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue templates allow you to quickly set appropriate default settings. You have the option to change these settings at any time."])},
      "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humanitarian aid"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing services"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-service restaurant"])},
      "simpleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuance of humanitarian aid is on a first-come, first-served basis. To join the queue, a person needs to scan a QR code. When the queue is ready, the client needs to scan the QR code or press the corresponding button in the application to start the service. The administrator can also initiate service. The status of the queue is always «Free», because the service process does not take much time"])},
      "serviceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services are provided on a first-come, first-served basis. To join the queue, a person needs to scan a QR code. When the queue is ready, the customer needs to scan the QR code or press the corresponding button in the application to start the service. The administrator can also initiate the service At the same time, the queue goes to the «Busy» state. The next customer will be invited when the queue state is «Free»"])},
      "restaurantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders are accepted in the order of a live queue, and service is in the order of food preparation. To join the queue, a person needs to scan a QR code. When the order is completed, the customer will receive an invitation. There is no possibility to give up the queue. And the service actually happens as an emergency"])}
    }
  }
}