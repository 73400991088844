import { DEF } from "./Defaults.js";

let sse = null;
let messenger = null;

function apiLog(msg, chan = 1) {
  if(DEF.log) {
    console.log('API-'+chan+': '+msg);
  }
}
async function engine(data, url = null) {
  return new Promise((resolve, reject) => {
    url = url ? url : DEF.apiUrl;
    fetch(url, {
      method: "POST",
      mode: "cors",                           // no-cors, *cors, same-origin
      cache: "no-cache",                      // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin",             // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",   // or 'application/x-www-form-urlencoded' 'application/json'
        Accept: "application/json",           // expected data sent back
      },
      redirect: "follow",                     // manual, *follow, error
      referrerPolicy: "no-referrer",          // no-referrer, *client
      body: JSON.stringify(data),             // body data type must match "Content-Type" header
    }).then(response => {
      if(response && response.ok) {
        let response_x = response.clone();
        response.json().then(result => {
          if(typeof result == "object") {
            apiLog('Ok. Fetched: ');
            apiLog(JSON.stringify(result));
            if(!result.error) {
              resolve(result);
            } else {
              apiLog('Server data error', 2);
              resolve(result);
            }
          } else {
            response_x.text().then(result => {
              apiLog('RAW:');
              apiLog(result);
            });            
            reject(new Error('API: Data error (bad object)'));
          }
        }).catch(() => {
          response_x.text().then(result => {
            apiLog('RAW:');
            apiLog(result);
          });           
          reject(new Error('API: Data error (bad json)'));
        });
      } else {
        reject(new Error('API: Response error'));
      }
    }).catch(()=>{
      reject(new Error('API: Data error (bad object)'));
    });
  });
}
export const apiGet = async function(data, url = null) {
  return engine(data, url).catch((err) => {
    apiLog(err.message, 2);
    wsMessage('error');
  }); 
}
// =============== SSE API ================
function wsMessage(message) {
  apiLog('SSE message: '+message);
  if(messenger) {
    messenger(message);
  }
}
export const wsSubscribe = async function(token, callback) {  // query token, ws callback
  return new Promise((resolve, reject) => {
    if(!sse) {
      apiGet({
        ver: DEF.apiVersion,
        method: "getWsToken",
        data: { token: token }
      },DEF.clientApiUrl).then((result) => {
        if(!result?.error && result?.result?.token) {
          messenger = callback;
          sse = new EventSource(DEF.sseUrl+"?token="+result?.result?.token);
          sse.addEventListener('message', (event) => {
            wsMessage(event.data);
          });
          sse.addEventListener('error', (event) => {
            wsMessage(event.data);
          });
          resolve(null);
        } else {
          reject(new Error('API: SSE connect server error'));
        }
      }).catch(()=>{
        reject(new Error('API: SSE get token error'));
      });
    } else {
      resolve(null);
    }
  });
}
export const wsUnsubscribe = function() {
	if(sse) {
		sse.close();
    sse = null;
		apiLog('SSE disconnected');
	}
  messenger = null;  
}